let host = ''

console.log(process.env.REACT_APP_HOST);

console.log(process.env);

if (process.env.NODE_ENV === "production") {
    host = process.env.REACT_APP_HOST
}

export default host
